import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { Layouts, TaplinkButton } from '../../../components/components';
import PromoPopup from './PromoPopup/PromoPopup';
import {
  StrapiTaplinksFirstPage,
  StrapiTaplinksTaplinkBlocks,
  StrapiTaplinksSecondPage,
  StrapiTaplinksTheme,
} from '../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import { colors } from '../../../styles/variables';
import ColorService from '../../../lib/services/colorService';
import { QuestionCircle } from '../../../Icons';
import { IconWrap } from './style';
import remarkGfm from 'remark-gfm';

interface TaplinkPropmoProps {
  getPromoPopupValue: (value: boolean) => void;
  data: StrapiTaplinksFirstPage | StrapiTaplinksTaplinkBlocks | StrapiTaplinksSecondPage;
  theme?: StrapiTaplinksTheme | null | undefined;
}

const TaplinkPropmo: React.FC<TaplinkPropmoProps> = ({ getPromoPopupValue, data, theme }) => {
  const { Container } = Layouts;
  const { title, secondTitle, description, picture, PromoPopup: popupData, link, CTA } = data;
  const [isPopup, setPopup] = useState(false);
  const colorService = new ColorService();
  useEffect(() => {
    getPromoPopupValue(isPopup);
  }, [isPopup]);
  return (
    <Container className={style.container}>
      <div className={style.promoWrapper}>
        <h1 className={style.title}>{title}</h1>
        <img className={style.promoImage} src={`${process.env.STRAPI_API_URL}${picture?.url}`} />
        {secondTitle && (
          <div className={style.project}>
            <h2 className={style.projectTitle}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={secondTitle || ''} remarkPlugins={[remarkGfm]} />
            </h2>
            <IconWrap fill={theme?.mainColor || colors.green} className={style.question} onClick={() => setPopup(true)}>
              <QuestionCircle />
            </IconWrap>
          </div>
        )}
        <div className={style.description}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={description || ''} remarkPlugins={[remarkGfm]} />
        </div>
        {CTA && (
          <div className={style.buttonWrapper}>
            <a href={link || '#'} target="_blank">
              <TaplinkButton
                label={CTA.buttonTite || 'Зарегистрироваться'}
                isActive={true}
                background={
                  theme?.mainColor && theme?.additionalColor
                    ? colorService.generateGradient(theme.mainColor, theme.additionalColor, 0)
                    : colors.defaultGradientButton
                }
                boxShadow={
                  theme?.mainColor ? colorService.generateShadow('0px 16px 20px -12px', theme.mainColor) : colors.defaultTaplinkButtonShadow
                }
                fontColor={theme?.textColor || colors.black}
              />
            </a>
          </div>
        )}
        {isPopup && <PromoPopup data={popupData} close={setPopup} />}
      </div>
    </Container>
  );
};

export default TaplinkPropmo;
