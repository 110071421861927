import React from 'react';
import style from './style.module.scss';
import { Link } from '../../../Icons';
import { socialData } from '../../../components/Footer/topContent/social/socialData';
import ListIcons from '../../../components/ListIcons/ListIcons';
import { StrapiTaplinksTaplinkBlocks, StrapiTaplinksTheme } from '../../../graphql-types';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/variables';
import { LinkIconWrap } from './style';

interface TaplinkFooterProps {
  data: StrapiTaplinksTaplinkBlocks | null | undefined;
  theme?: StrapiTaplinksTheme | null | undefined;
}

const TaplinkFooter: React.FC<TaplinkFooterProps> = ({ data, theme }) => {
  const { t } = useTranslation();
  return (
    <div className={style.taplinkFooter}>
      <div className={style.top}>
        <h3 className={style.topTitle}>{t('footer:taplink.topTitle')}</h3>
        <div className={style.linksList}>
          {data &&
            data.linksList &&
            data.linksList.length !== 0 &&
            data.linksList.map((link, index) => (
              <a className={style.linkItem} href={link?.link || '#'} key={link?.id || index}>
                <LinkIconWrap background={theme?.mainColor || colors.green} className={style.icon}>
                  <Link />
                </LinkIconWrap>
                <div style={{ color: theme?.mainColor || colors.green }} className={style.label}>
                  {link?.title}
                </div>
              </a>
            ))}
        </div>
      </div>
      <div className={style.bottom}>
        <h3 className={style.bottomTitle}>{t('footer:taplink.bottomTitle')}</h3>
        <div className={style.socialIconList}>
          {socialData.map(iconKey => {
            return <ListIcons width={'40px'} height={'40px'} itemKey={iconKey} key={iconKey} socialIcon={data?.socialItem} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default TaplinkFooter;
