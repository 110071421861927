import React, { useState } from 'react';
import style from './style.module.scss';
import { Layouts, TaplinkButton } from '../../../components/components';
import { Modal } from 'antd';
import SliderBlock from '../../SharedModules/SliderBlock/SliderBlock';
import { SliderBlocksType } from '../../../lib/constants/enums';
import {
  StrapiTaplinksFirstPage,
  StrapiTaplinksTaplinkBlocks,
  StrapiTaplinksSecondPage,
  StrapiTaplinksTheme,
} from '../../../graphql-types';
import { Close } from '../../../Icons';
import { colors } from '../../../styles/variables';

interface TaplinkReviewsProps {
  data: StrapiTaplinksFirstPage | StrapiTaplinksTaplinkBlocks | StrapiTaplinksSecondPage;
  theme?: StrapiTaplinksTheme | null | undefined;
}

const TaplinkReviews: React.FC<TaplinkReviewsProps> = ({ data, theme }) => {
  const { label } = data;
  const { Container } = Layouts;
  const [isModal, setModal] = useState(false);

  return (
    <Container className={style.container}>
      <div onClick={() => setModal(true)}>
        <TaplinkButton
          label={label}
          withIcon={true}
          fontColor={theme?.textColor || colors.black}
          background={colors.white}
          isReview={true}
        />
      </div>
      <Modal visible={isModal} onCancel={() => setModal(false)} footer={null} centered closeIcon={<Close />}>
        <SliderBlock data={data} type={SliderBlocksType.taplinkReviews} />
      </Modal>
    </Container>
  );
};

export default TaplinkReviews;
