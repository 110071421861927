import React, { useState } from 'react';
import style from './taplink_page.module.scss';
import { Layouts, CardItem, TaplinkButton } from '../components/components';
import { SliderBlock } from '../modules/modules';
import TaplinkPromo from '../modules/TaplinkModules/TaplinkPromo/TaplinkPromo';
import TaplinkSubscribeBlock from '../modules/TaplinkModules/TaplinkSubscribeBlock/TaplinkSubscribeBlock';
import TaplinkTabs from '../modules/TaplinkModules/TaplinkTabs/TaplinkTabs';
import TaplinkFooter from '../modules/TaplinkModules/TaplinkFooter/TaplinkFooter';
import TaplinkReviews from '../modules/TaplinkModules/TaplinkReviews/TaplinkReviews';
import { StrapiTaplinks, StrapiTaplinksTaplinkBlocks } from '../graphql-types';
import cx from 'classnames';
import { TaplinkBlocksType, StrapiTaplinkBlockName } from '../lib/constants/strapi';
import { CardItemType, SliderBlocksType } from '../lib/constants/enums';
import { replaceStrapiComponentName } from '../lib/utils';
import { colors } from '../styles/variables';
import ColorService from '../lib/services/colorService';

interface PageContext {
  taplink: StrapiTaplinks;
  slug: string;
  lang: string;
}

export interface IndexProps {
  pageContext: PageContext;
}

const PageTemplate: React.FC<IndexProps> = ({ pageContext }) => {
  const {
    taplink: { FirstPage, SecondPage, TaplinkBlocks, theme },
  } = pageContext;
  const { Page, ScrollToTop, Container } = Layouts;
  const [isPromoPopup, setPropmoPopup] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const getPromoPopupValue = (value: boolean) => setPropmoPopup(value);
  const colorService = new ColorService();

  const renderButton = (label: string, description?: string, isDownloadType: boolean = false) => (
    <TaplinkButton
      label={label || ''}
      description={description || ''}
      isDownloadType={isDownloadType}
      background={
        theme?.mainColor && theme?.additionalColor
          ? colorService.generateGradient(theme.mainColor, theme.additionalColor, 0)
          : colors.defaultGradientButton
      }
      boxShadow={theme?.mainColor ? colorService.generateShadow('0px 16px 20px -12px', theme.mainColor) : colors.defaultTaplinkButtonShadow}
      fontColor={theme?.textColor || colors.black}
    />
  );

  const renderBlocks = (blocksArray: StrapiTaplinksTaplinkBlocks[]) => {
    return blocksArray.map((taplink, index) => {
      switch (replaceStrapiComponentName(taplink.__component)) {
        case StrapiTaplinkBlockName.taplinkPromo:
          return <TaplinkPromo data={taplink} theme={theme} getPromoPopupValue={getPromoPopupValue} key={index} />;
        case StrapiTaplinkBlockName.taplinkLinksList:
          return (
            <React.Fragment key={index}>
              {taplink?.title && <h3 className={style.taplinkTitle}>{taplink.title}</h3>}
              <div className={style.linksList}>
                {taplink.LinkItem &&
                  taplink.LinkItem.map((link, index) => (
                    <div className={style.buttonItem} key={link?.id || index}>
                      <a href={link?.link || '#'}>{link?.title && renderButton(link.title, link.description || '')}</a>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          );
        case StrapiTaplinkBlockName.taplinkCheckList:
          return (
            <div className={style.chekList} key={index}>
              <h3 className={style.taplinkTitle}>{taplink.title}</h3>
              <div>
                {taplink.linksList &&
                  taplink.linksList.length !== 0 &&
                  taplink.linksList.map((link, index) => (
                    <div className={style.buttonItem} key={link?.id || index}>
                      <a href={link?.link || '#'}>{link?.title && renderButton(link.title, '', true)}</a>
                    </div>
                  ))}
              </div>
            </div>
          );
        case StrapiTaplinkBlockName.taplinkContact:
          return (
            <div className={style.taplinkContact} key={index}>
              <h3 className={style.taplinkTitle}>{taplink.title}</h3>
              <a
                style={{ color: theme?.mainColor || colors.green }}
                className={style.taplinkContactMail}
                href={taplink.mail ? `mailto:${taplink.mail}` : '#'}
              >
                {taplink.mail}
              </a>
              <div className={style.buttonItem}>
                <a href={taplink?.link || '#'}>{taplink?.buttontitle && renderButton(taplink.buttontitle)}</a>
              </div>
            </div>
          );
        case StrapiTaplinkBlockName.taplinkVideoBlock:
          return (
            <CardItem
              type={CardItemType.taplinkVideo}
              video={{
                src: (taplink.video && taplink.video.url) || '',
                poster: (taplink.poster && taplink.poster.url) || '',
                link: taplink.videoLink || '',
              }}
              title={taplink.title || ''}
              description={taplink.description || ''}
              key={index}
            />
          );
        case StrapiTaplinkBlockName.taplinkVideoSlider:
          return <SliderBlock type={SliderBlocksType.taplinkVideo} data={taplink} key={index} />;
        case StrapiTaplinkBlockName.taplinkSubscribe:
          return <TaplinkSubscribeBlock data={taplink} theme={theme} key={index} />;
        case StrapiTaplinkBlockName.taplinkReviews:
          return <TaplinkReviews data={taplink} key={index} />;
        case StrapiTaplinkBlockName.taplinkPostSlider:
          return <SliderBlock type={SliderBlocksType.taplinkDefault} data={taplink} key={index} />;
        default:
          return null;
      }
    });
  };

  const titleForNav = FirstPage?.find(block => block?.blockTypes?.blockTypes === TaplinkBlocksType.taplinkNav);

  return (
    <>
      <ScrollToTop value={currentPage} />
      <Page>
        <div className={cx(style.wrapper, { [style.wrapperOverflow]: isPromoPopup })}>
          <Container className={style.taplinkContainer}>
            {FirstPage && SecondPage && FirstPage?.length !== 0 ? (
              <div style={{ marginTop: FirstPage && FirstPage.length !== 0 ? '60px' : '0' }}>
                <TaplinkTabs
                  title={{ firstTitle: titleForNav?.titleFirstTab || '', secondTitle: titleForNav?.titleSecondTab || '' }}
                  firstTab={renderBlocks(FirstPage)}
                  secondTab={renderBlocks(SecondPage)}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              renderBlocks(TaplinkBlocks)
            )}
            {/* <div className={style.workouts}>
              <div className={style.workoutsHead}>
                <h4 className={style.workoutsTitle}>РАЗМИНКА | ЗАМИНКА</h4>
                <div className={style.workoutsArrow}></div>
              </div>
              <div className={style.workoutsDescription}>Перед любой тренировкой делайте разминку, а после тренировки заминку</div>
            </div>
            <div className={style.workoutsList}>
              <h4 className={style.workoutsListTitle}>ТРЕНИРОВКИ ПО ГРУППАМ МЫШЦ</h4>
              <div className={style.workoutsListFlex}>
                <div className={style.workoutsItem}>
                  <img src={`${process.env.STRAPI_API_URL}/uploads/Image_5ebfc7432b.png`} alt="" />
                  <div className={style.workoutsItemContent}>
                    <h5>Пресс</h5>
                    <p>6 тренировок</p>
                  </div>
                </div>
                <div className={style.workoutsItem}>
                  <img src={`${process.env.STRAPI_API_URL}/uploads/Image_5ebfc7432b.png`} alt="" />
                  <div className={style.workoutsItemContent}>
                    <h5>Пресс</h5>
                    <p>6 тренировок</p>
                  </div>
                </div>
                <div className={style.workoutsItem}>
                  <img src={`${process.env.STRAPI_API_URL}/uploads/Image_5ebfc7432b.png`} alt="" />
                  <div className={style.workoutsItemContent}>
                    <h5>Пресс</h5>
                    <p>6 тренировок</p>
                  </div>
                </div>
                <div className={cx(style.workoutsItem, style.workoutsItemLong)}>
                  <img src={`${process.env.STRAPI_API_URL}/uploads/Image_5ebfc7432b.png`} alt="" />
                  <div className={style.workoutsItemContent}>
                    <h5>Пресс</h5>
                    <p>6 тренировок</p>
                  </div>
                </div>
              </div>
            </div> */}
          </Container>
          <TaplinkFooter
            data={TaplinkBlocks?.find(block => replaceStrapiComponentName(block?.__component) === StrapiTaplinkBlockName.taplinkFooter)}
            theme={theme}
          />
        </div>
      </Page>
    </>
  );
};

export default PageTemplate;
