import React from 'react';
import style from './style.module.scss';
import { Layouts, TaplinkButton } from '../../../components/components';
import {
  StrapiTaplinksFirstPage,
  StrapiTaplinksTaplinkBlocks,
  StrapiTaplinksSecondPage,
  StrapiTaplinksTheme,
} from '../../../graphql-types';
import RectMarkdown from 'react-markdown';
import { colors } from '../../../styles/variables';
import ColorService from '../../../lib/services/colorService';
import remarkGfm from 'remark-gfm';

interface TaplinkSubscribeBlockProps {
  data: StrapiTaplinksFirstPage | StrapiTaplinksTaplinkBlocks | StrapiTaplinksSecondPage;
  blockName?: string;
  theme?: StrapiTaplinksTheme | null | undefined;
}

const TaplinkSubscribeBlock: React.FC<TaplinkSubscribeBlockProps> = ({ data, theme }) => {
  const { Container } = Layouts;
  const { title, description, picture } = data;
  const colorService = new ColorService();
  return (
    <Container className={style.container}>
      <div className={style.checkListItem}>
        {picture && <img className={style.picture} src={`${process.env.STRAPI_API_URL}${picture.url}`} alt={title || ''} />}
        <div className={style.info}>
          {title && <h4>{title}</h4>}
          {description && (
            <div className={style.subscribeDescription}>
              <RectMarkdown children={description} remarkPlugins={[remarkGfm]} />
            </div>
          )}
          <div className={style.linksList}>
            <a className={style.link} href={data.link || '#'} target="_blank">
              <TaplinkButton
                fontWeight={500}
                label={(data.CTA && data.CTA.buttonTitle) || 'Подписаться'}
                background={
                  (theme?.mainColor &&
                    theme?.additionalColor &&
                    colorService.generateGradient(theme.mainColor, theme.additionalColor, 0)) ||
                  colors.defaultGradientButton
                }
                boxShadow={
                  (theme?.mainColor && colorService.generateShadow('0px 16px 20px -12px', theme.mainColor)) ||
                  colors.defaultTaplinkButtonShadow
                }
                fontColor={theme?.textColor || colors.black}
              />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TaplinkSubscribeBlock;
