import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { Tabs } from 'antd';
import { Layouts, Button } from '../../../components/components';

interface TaplinkTabsProps {
  firstTab: React.ReactNode;
  secondTab: React.ReactNode;
  title: {
    firstTitle: string;
    secondTitle: string;
  };
  setCurrentPage: (value: number) => void;
}

const TaplinkTabs: React.FC<TaplinkTabsProps> = ({ firstTab, secondTab, title, setCurrentPage }) => {
  const { TabPane } = Tabs;
  const { Container } = Layouts;
  const [key, setKey] = useState(1);
  const tabs = [firstTab, secondTab];
  useEffect(() => {
    setCurrentPage(key);
  }, [key]);
  return (
    <>
      <div className={style.wrapper}>
        <Container className={style.container}>
          <Tabs defaultActiveKey="1" centered onChange={activeKey => setKey(Number(activeKey))}>
            {tabs.map((tab, index) => (
              <TabPane tab={<div>{index === 0 ? title.firstTitle : title.secondTitle}</div>} key={index + 1}>
                {tab}
              </TabPane>
            ))}
          </Tabs>
        </Container>
      </div>
    </>
  );
};

export default TaplinkTabs;
