import React from 'react';
import style from './style.module.scss';
import { TaplinkButton } from '../../../../components/components';
import ReactMarkdown from 'react-markdown';
import { StrapiTaplinksTheme } from '../../../../graphql-types';
import { colors } from '../../../../styles/variables';
import ColorService from '../../../../lib/services/colorService';
import remarkGfm from 'remark-gfm';

interface PromoPopupProps {
  close: (param: boolean) => void;
  data: {
    description: string;
    link: string;
    title: string;
    buttonTitle?: string;
  };
  theme?: StrapiTaplinksTheme | null | undefined;
}

const PromoPopup: React.FC<PromoPopupProps> = ({ close, data, theme }) => {
  const { title, description, buttonTitle, link } = data;
  const colorService = new ColorService();
  return (
    <div className={style.popup}>
      <div className={style.content}>
        <div className={style.cancel} onClick={() => close(false)} />
        <h2 className={style.title}>{title}</h2>
        <div className={style.description}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={description || ''} remarkPlugins={[remarkGfm]} />
        </div>
        {buttonTitle && link && (
          <a className={style.link} href={link || '#'} target="_blank">
            <TaplinkButton
              label={buttonTitle}
              background={
                theme?.mainColor && theme?.additionalColor
                  ? colorService.generateGradient(theme.mainColor, theme.additionalColor, 0)
                  : colors.defaultGradientButton
              }
              boxShadow={
                theme?.mainColor ? colorService.generateShadow('0px 16px 20px -12px', theme.mainColor) : colors.defaultTaplinkButtonShadow
              }
              fontColor={theme?.textColor || colors.black}
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default PromoPopup;
